//REACTJS
import React, { useEffect, useState } from 'react';
//GATSBYJS
import { navigate, Link } from 'gatsby';
//FORMIK & YUP
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
//AWS-AMPLIFY
import { Auth, Hub } from 'aws-amplify';
//CONTEXT-API
import { useStateValue } from '../context/StateContext';
import { settingISCONFIRMEDSIGNUP, settingROLE, settingUSERNAME } from '../context/contextapi';

// Icons
import { LuMailOpen } from "react-icons/lu";
import { PiLockLight } from "react-icons/pi";
import { FiUser } from "react-icons/fi";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { FiEye, FiEyeOff } from 'react-icons/fi'; // Import eye icons from react-icons

// Toast
import { Toaster, toast } from 'react-hot-toast';

// Helmet 
import Helmet from "react-helmet";
import { checkUserExists } from '../appsync_functions/mutations';

export default function SignUp() {
    const [{ isSignedin }, dispatch] = useStateValue();
    const [loading, setLoading] = useState(false)
    const [user, setUser] = useState(null);
    const [showPassword, setShowPassword] = useState(false);

    // Toggle password visibility
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };


    useEffect(() => {
        Hub.listen('auth', ({ payload: { event, data } }) => {
            switch (event) {
                case 'signIn':
                case 'cognitoHostedUI':
                    getUser().then((userData) => {
                        setUser(userData);
                        console.log('Signed In:', userData);
                    });
                    break;
                case 'signOut':
                    setUser(null);
                    break;
                case 'signIn_failure':
                case 'cognitoHostedUI_failure':
                    console.log('Sign in failure', data);
                    break;
                default:
            }
        });

        getUser().then((userData) => {
            setUser(userData);
            console.log('Signed In:', userData);
        });
    }, []);


    function getUser() {
        return Auth.currentAuthenticatedUser()
            .then((userData) => userData)
            .catch(() => console.log('Not signed in'));
    }


    const initialValues = {
        email: "",
        username: "",
        password: "",
        role: ""
    }


    const validationSchema = Yup.object().shape({
        email: Yup
            .string()
            .email('Must be a valid email')
            .required('Email is required'),
        username: Yup
            .string()
            .min(5, 'Must be at least 5 characters')
            .max(20, 'Must be at most 20 characters')
            .required('Username is required'),
        role: Yup
            .string()
            .min(3, 'Must be at least 3 characters')
            .max(20, 'Must be at most 20 characters')
            .required('Role is required'),
        password: Yup
            .string()
            .min(6, 'Must be at least 6 characters')
            .matches(/[a-z]/, 'Must have at least 1 lowercase letter')
            .matches(/[A-Z]/, 'Must have at least 1 uppercase letter')
            .matches(/[0-9]/, 'Must have at least 1 number')
            .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Must have at least 1 symbol')
            .required('Password is required')
    });


    const onSubmit = async (values, { setSubmitting }) => {
        setSubmitting(true);
        setLoading(true)

        setTimeout(async () => {
            try {
                /* using here due to ContextApi disptach accessable only in react components */
                const userExists = await checkUserExists(values.username)
                if (userExists) {
                    throw new Error('User already exists')
                } 
                await Auth.signUp({
                    username: values.username,
                    password: values.password,
                    attributes: {
                        email: values.email,       //optional
                        phone_number: ''           //optional
                    }
                });
                console.log('sended signup code');

                dispatch(settingISCONFIRMEDSIGNUP(true));
                dispatch(settingUSERNAME(values.username));
                dispatch(settingROLE(values.role));
                toast.success('Otp sent successfully', {
                    duration: 4000,
                    position: window.matchMedia("(min-width: 600px)").matches ? "bottom-right" : "bottom-center",

                    style: {
                        backgroundColor: '#d9d9d9',
                        padding: window.matchMedia("(min-width: 600px)").matches ? "20px 30px" : "15px 20px",
                        fontSize: '14px',
                        fontWeight: 'bold'
                    },
                });
                navigate("/code");
            }
            catch (error) {
                console.log('error signup', error);
                toast.error(error.message.toString(), {
                    duration: 4000,
                    position: window.matchMedia("(min-width: 600px)").matches ? "bottom-right" : "bottom-center",

                    style: {
                        backgroundColor: '#d9d9d9',
                        padding: window.matchMedia("(min-width: 600px)").matches ? "20px 30px" : "15px 20px",
                        fontSize: '14px',
                        fontWeight: 'bold'
                    },
                });
            }
            finally {
                setLoading(false)
            }
            setSubmitting(false);
        }, 1500);
    }


    useEffect(() => {
        if (user) {
            navigate("/");
        }
    }, [user]);


    if (user) return <></>;


    return (
        <div className='flex justify-center font-poppins'>
            <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
            </Helmet>
            <Toaster />
            <div className='w-full md:w-[600px] min-h-[100dvh] flex flex-col px-5 pt-10'>
                <div>
                    <div className='flex justify-end'>
                        <Link to="/login" className='text-primary text-sm text-right'>Sign in</Link>
                    </div>
                    <div className='mt-7'>
                        <h2 className='text-lg text-zinc-800 font-semibold'>1 Minute Registration</h2>
                        <p className='text-sm text-zinc-600'>Add your details below</p>
                    </div>
                </div>
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                    {({ isSubmitting }) => (
                        <Form className="rounded-md flex-grow flex flex-col gap-5 mt-8 sm:mt-16">
                            <div className="flex flex-col gap-2">
                                <label htmlFor="email" className="text-zinc-600 font-medium text-xs sm:text-sm">
                                    Email Address
                                </label>
                                <div className='rounded-lg border-[0.9px] border-zinc-300 py-3 px-4 flex items-center gap-3'>
                                    <LuMailOpen className='text-zinc-400' />
                                    <Field
                                        type="email"
                                        name="email"
                                        id="email"
                                        className="w-full text-sm focus:outline-none placeholder:text-xs"
                                        placeholder='e. g. "example@mail.com"'
                                    />
                                </div>
                                <ErrorMessage name="email" component="div" className="text-red-500 text-xs" />
                            </div>
                            <div className="flex flex-col gap-2">
                                <label htmlFor="username" className="text-zinc-600 font-medium text-xs sm:text-sm">
                                    Username
                                </label>
                                <div className='rounded-lg border-[0.9px] border-zinc-300 py-3 px-4 flex items-center gap-3'>
                                    <FiUser className='text-zinc-400' />
                                    <Field
                                        type="text"
                                        name="username"
                                        id="username"
                                        className="w-full text-sm focus:outline-none placeholder:text-xs"
                                        placeholder="Enter the username"
                                    />
                                </div>
                                <ErrorMessage name="username" component="div" className="text-red-500 text-xs" />
                            </div>
                            <div className="flex flex-col gap-2">
                                <label htmlFor="password" className="text-zinc-600 font-medium text-xs sm:text-sm">
                                    Password
                                </label>
                                <div className='rounded-lg border-[0.9px] border-zinc-300 py-3 px-4 flex items-center gap-3'>
                                    <Field
                                        type={showPassword ? "text" : "password"} // Toggle between "text" and "password"
                                        name="password"
                                        id="password"
                                        className="w-full text-sm focus:outline-none placeholder:text-xs"
                                        placeholder="Enter the password"
                                    />
                                    <button
                                        type="button"
                                        onClick={togglePasswordVisibility}
                                        className="focus:outline-none text-zinc-400"
                                    >
                                        {showPassword ? <FiEyeOff /> : <FiEye />} {/* Show appropriate icon */}
                                    </button>
                                </div>
                                <ErrorMessage name="password" component="div" className="text-red-500 text-xs" />
                            </div>
                            <div className="flex flex-col gap-2">
                                <label htmlFor="role" className="text-zinc-600 font-medium text-xs sm:text-sm">
                                    Are you a customer or a massage therapist?
                                </label>
                                <div className='rounded-lg border-[0.9px] border-zinc-300 py-3 px-4 flex items-center gap-3'>
                                    {/* Replace PiLockLight with a suitable icon for role selection */}
                                    <Field
                                        as="select" // Use "as" to specify that this is a select field
                                        name="role"
                                        id="role"
                                        className="w-full text-sm focus:outline-none placeholder:text-xs"
                                    // defaultValue=''
                                    >
                                        <option value="" disabled>Select</option>
                                        <option value="business">Therapist</option>
                                        <option value="customer">Customer</option>
                                        {/* Add more roles as necessary */}
                                    </Field>
                                </div>
                                <ErrorMessage name="role" component="div" className="text-red-500 text-xs" />
                            </div>
                            <div className='mt-auto flex justify-center pt-10'>
                                <button
                                    type="submit"
                                    className="bg-primary text-white font-medium p-2 pl-4 flex justify-between items-center rounded-full w-[200px] text-center text-sm"
                                    disabled={isSubmitting}
                                >
                                    {
                                        loading ? 'loading ...' :
                                            'Create Account'
                                    }
                                    <div className='w-[30px] h-[30px] flex justify-center items-center rounded-full bg-[#556FFF]'>
                                        <MdOutlineKeyboardArrowRight className='font-bold' />
                                    </div>
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
                <div className='border-t-[0.9px] border-zinc-300 py-10 mt-10'>
                    <p className='text-sm text-center text-zinc-600'>Need help? <span className='text-primary'>Contact our support team</span></p>
                </div>
            </div>
        </div >
    )
}


export function Head() {
    return (
        <title> Signup | MassageOpen </title>
    )
}
